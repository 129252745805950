import React, { Component } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';


export class DisclaimerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }


  render() {
    return (
      <Modal
        show={this.props.showDisclaimer}
        onHide={this.props.hideDisclaimer}
        dialogClassName='modals'
        aria-labelledby='disclaimerModal'
      >
        <div className='inner'>
          <div className='closeIconDiv'>
            <Image
              className='closeIcon'
              onClick={() => this.props.hideDisclaimer()}
              src={require('../Styles/Images/thickGreyCloseIcon.png')}
            />
          </div>
          <p className='mobileSize25 mobile25Line22 desktopSize30 desktop30Line29 marginB1em'>Disclaimer and Limited License Grant:</p>
          <p className='mobileSize16 mobile16Line22 desktopSize20 desktop20Line29'>The Vendor Security Alliance (VSA) questionnaire and all related material (the “Licensed Material”) is provided AS IS, with no representations or warranties of any kind, whether express, implied, statutory, or other. This includes, without limitation, warranties of title, merchantability, fitness for a particular purpose, non-infringement, absence of latent or other defects, accuracy, or the absence of errors, whether or not known or discoverable. VSA and its members will have no liability under any legal theory (including, without limitation, negligence) or otherwise for any direct, special, indirect, incidental, consequential, punitive, exemplary, or other losses, costs, expenses, or damages arising out of use of the Licensed Material, even if advised of the possibility of such losses, costs, expenses, or damages.</p>
          <p className='mobileSize16 mobile16Line22 desktopSize20 desktop20Line29'>VSA grants a limited right, under its copyright rights in the Licensed Material, for users of the Licensed Material to download a copy of the Licensed Material and reproduce and distribute unmodified copies for sole purpose of (a) a particular user evaluating its own internal security processes and the security practices of its direct vendors, or (b) providing Feedback to VSA. All other uses are prohibited (including, without limitation, using the Licensed Material in connection with a security consulting or hosted vendor management service), and no additional intellectual property rights are granted by VSA to any party. “Feedback” means any suggested changes to the Licensed Material. VSA will have the right to reproduce, distribute, perform, display and create derivative works of all Feedback, and use all Feedback without restriction and without payments to any party.</p>
          <div className='flexRow justifyCenter'>
            <Button className='redButton medium' onClick={() => this.props.hideDisclaimer()}>
              <span className='mobileSize18 mobile18Line23 weightBold'>I Agree</span>
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}