import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


export class DirectorsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }


  render() {

    this.image = '';
    this.name = '';
    this.title = '';
    this.linkedinUrl = '';

    if (this.props.image) {
      this.image = this.props.image;
    }

    if (this.props.name) {
      this.name = this.props.name;
    }

    if (this.props.title) {
      this.title = this.props.title;
    }

    if (this.props.linkedinUrl) {
      this.linkedinUrl = this.props.linkedinUrl;
    }

    return (
      <div className='directorsCard'>
        <div className='imageDiv'>
          <Image
            className='innerImg'
            src={this.image}
          />
        </div>
        <p className='mobileSize25 mobile25Line26 noMarginB'>{this.name}</p>
        <p className='mobileSize20 mobile20Line26 marginB6px colorGrey'>{this.title}</p>
        <a href={this.linkedinUrl} target='_blank' rel='noopener noreferrer'>
          <Image
            className='linkedinIcon'
            src={require('../../Styles/Images/linkedinIcon.png')}
          />
        </a>
      </div>
    )
  }
}