import DrKenBaylor from '../../Styles/Images/ken-baylor.jpg';
import daneStuckey from '../../Styles/Images/dane-stuckey.jpg';
import nateJones from '../../Styles/Images/nate-jones.jpg';
import garyMiller from '../../Styles/Images/gary-miller.jpg';
import fredrickLee from '../../Styles/Images/fredrick-lee.jpg';
import toddRedfoot from '../../Styles/Images/todd-redfoot.jpg';
import karimAdib from '../../Styles/Images/karim-adib.jpg';
import balaNatarajan from '../../Styles/Images/bala-natarajan.jpg';


export const BoardOfDirectorsData = [
  {
    id: 'DrKenBaylor',
    image: DrKenBaylor,
    name: 'Dr. Ken Baylor',
    title: 'Former Head of Compliance at Uber and CISO at Symantec',
    linkedinUrl: 'https://www.linkedin.com/in/kenbaylor/',
  },
  {
    id: 'daneStuckey',
    image: daneStuckey,
    name: 'Dane Stuckey',
    title: 'Acting CISO at Palantir Technologies',
    linkedinUrl: 'https://www.linkedin.com/in/danestuckey/',
  },
  {
    id: 'nateJones',
    image: nateJones,
    name: 'Nate Jones',
    title: 'Security Program Manager at Airbnb',
    linkedinUrl: 'https://www.linkedin.com/in/nathanjones/',
  },
  {
    id: 'garyMiller',
    image: garyMiller,
    name: 'Gary Miller',
    title: 'Head of Information Security at TaskUs',
    linkedinUrl: 'https://www.linkedin.com/in/gary-miller-56a2111/',
  },
  {
    id: 'fredrickLee',
    image: fredrickLee,
    name: 'Fredrick Lee',
    title: 'Head Of Information Security at Square',
    linkedinUrl: 'https://www.linkedin.com/in/fredrickdlee/',
  },
  {
    id: 'toddRedfoot',
    image: toddRedfoot,
    name: 'Todd Redfoot',
    title: 'Chief Privacy and Risk Officer at Go Daddy Software',
    linkedinUrl: 'https://www.linkedin.com/in/toddredfoot/',
  },
  {
    id: 'karimAdib',
    image: karimAdib,
    name: 'Karim Adib',
    title: 'Senior Director of Science and Engineering at RapportBoost.AI',
    linkedinUrl: 'https://www.linkedin.com/in/karim-adib-24120b8b/',
  },
  {
    id: 'balaNatarajan',
    image: balaNatarajan,
    name: 'Bala Natarajan',
    title: 'Senior Manager Information Security at Pivotal',
    linkedinUrl: 'https://www.linkedin.com/in/bnatarajan1/',
  },
]