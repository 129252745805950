import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Image
} from 'react-bootstrap';

import { HamburgerMenu } from './HamburgerMenu';


export class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }

    this._isMounted = false;
  }

  toggleMenu = () => {
    if (this._isMounted && !this.state.menuOpen) {
      this.setState({
        menuOpen: true,
      })
    } else if (this._isMounted && this.state.menuOpen) {
      this.setState({
        menuOpen: false,
      })
    }

  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className='mobileHeader flexRow alignCenter'>
        <div>
          <Image
            className='menuIcon'
            onClick={this.toggleMenu}
            src={require('../Styles/Images/hamburgerMenu.png')}
          />
        </div>

        <div>
          <Link to='/'>
            <Image
              className='logo'
              src={require('../Styles/Images/VSA-logo.png')}
            />
          </Link>
        </div>

        <HamburgerMenu
          menuOpen={this.state.menuOpen}
          toggleMenu={this.toggleMenu}
        />
      </div>
    )
  }
}