import React, { Component } from "react";
import { Container, Col, Image, Row, Button } from "react-bootstrap";
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/footer";

export class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    return (
      <div className="mainContainer partners">
        <div className="jumboTron short flexColumn justifyCenter alignCenter">
          <Header />
          <div className="inner">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 weightBold colorWhite textCenter">
              Partners
            </p>
          </div>
        </div>

        <div className="mainContent">
          <div className="sections one">
            <Container className="partnerContainer">
              <Row className="partnerRow">
                <Col md={6} lg={6} xs={12} className="imageContainer">
                  <p className="mobileSize18 mobile18Line23 desktopSize20   colorBlack textCenter">
                    The Vendor Security Alliance has partnered with{" "}
                    <a
                      href="https://whistic.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Whistic
                    </a>{" "}
                    to to be the online platform for our industry-leading
                    Security Assessment program: VSA FULL
                  </p>
                  <div className="flexRow justifyCenter">
                    <a
                      className="buttonText"
                      href="http://resources.whistic.com/vendor-security-alliance"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="redButton medium">
                        <span className="mobileSize18 weightBold">
                          Learn More
                        </span>
                      </Button>
                    </a>
                  </div>
                </Col>
                <Col md={6} lg={6} xs={12} className="imageContainer">
                  <Image src={require("../../Styles/Images/whistic.svg")} />
                </Col>
              </Row>

              <Row className="partnerRow">
                <Col md={6} lg={6} xs={12} className="imageContainer">
                  <p className="mobileSize18 mobile18Line23 desktopSize20   colorBlack textCenter">
                    The Vendor Security Alliance has partnered with{" "}
                    <a
                      href="https://www.vendorpedia.com/vsa-free-edition-form/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OneTrust
                    </a>{" "}
                    to to be the online platform for our new combined Security
                    Assessment & Privacy program: VSA CORE
                  </p>
                  <div className="flexRow justifyCenter">
                    <a
                      className="buttonText"
                      href="https://www.vendorpedia.com/vsa-free-edition-form/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="redButton medium">
                        <span className="mobileSize18 weightBold">
                          Learn More
                        </span>
                      </Button>
                    </a>
                  </div>
                </Col>
                <Col md={6} lg={6} xs={12} className="imageContainer">
                  <Image src={require("../../Styles/Images/onetrust.jpg")} />
                </Col>
              </Row>
            </Container>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
