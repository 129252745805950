import React, { Component } from "react";
import { Image, Form, Button } from "react-bootstrap";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { Header } from "../../Components/Header";
import { MediumRedButton } from "../../Components/MediumRedButton";
import { Footer } from "../../Components/footer";
import { API } from "aws-amplify";

export class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesPerPage: 1,
      fullName: "",
      email: "",
      subject: "",
      message: "",
      submitContactForm: false
    };

    this._isMounted = false;
  }

  checkScreenSizes = () => {
    if (window.innerWidth >= 1200) {
      this.setState({
        slidesPerPage: 4
      });
    } else if (window.innerWidth >= 1000) {
      this.setState({
        slidesPerPage: 3
      });
    } else if (window.innerWidth >= 550) {
      this.setState({
        slidesPerPage: 2
      });
    } else {
      this.setState({
        slidesPerPage: 1
      });
    }
  };

  getFormInfo = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  submitContactForm = e => {
    e.preventDefault();
    API.post("vsa-frontend", "addusercontactform", {
      body: {
        userName: this.state.fullName,
        userEmail: this.state.email,
        subject: this.state.subject,
        message: this.state.message
      }
    })
      .then(response => {
        this.setState({
          submitContactForm: true
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillMount() {
    this._isMounted = true;
    this._isMounted &&
      window.addEventListener("resize", this.checkScreenSizes.bind(this));
    this._isMounted && this.checkScreenSizes();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.checkScreenSizes.bind(this));
  }

  render() {
    return (
      <div className="mainContainer home">
        <div className="jumboTron heroHome tall flexColumn justifyCenter alignCenter">
          <Header />
          <div className="inner">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 weightBold colorWhite mobile30MarginB45 textCenter title">
              We are a coalition of companies committed to improving Internet
              security.
            </p>
            <p className="mobileSize20 mobile20Line26 desktopSize25 desktop25Line33 colorWhite textCenter">
              Every day, industries across the globe depend on each other to
              embrace sound cybersecurity practices: yet in the past companies
              have not had a standardized way to assess the security of their
              peers. The VSA was formed to solve these issues and streamline
              vendor security compliance.
            </p>
          </div>
        </div>
        <div className="mainContent">
          <div className="sections twoColumnRow two">
            <div className="twoColumnRowColumns downloadColumn">
              <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 colorBlack mobile30MarginB20">
                Download Our FREE Questionnaires
              </p>
              <p className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line26 colorGrey">
                The VSA issues two free questionnaires, which will be updated
                annually:
              </p>
              <p className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line26 colorGrey">
                <span className="weightBold">VSA-Full:</span> This is the
                classic VSA questionnaire which focuses deeply on vendor
                security. It is used by thousands of companies globally.
              </p>
              <p className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line26 colorGrey">
                <span className="weightBold">VSA-Core:</span> This
                questionnaire, first available on October 24th, 2019, comprises
                the most critical questions on vendor security in addition to
                privacy. The privacy section covers both US Privacy (data breach
                notification requirements plus the new California data privacy
                law (CCPA)), plus EU Privacy (General Data Protection Regulation
                (GDPR)).
              </p>
              <MediumRedButton
                text={"Download Questionnaires"}
                linkTo={"/downloadQuestionaire"}
              />
            </div>

            <div className="twoColumnRowColumns">
              <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 colorBlack mobile30MarginB20">
                Become a member of the VSA
              </p>
              <p className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line26 colorGrey">
                Members of the VSA may leverage our network of third party
                auditors, to carry out risk based assessments of their vendors;
                enabling members to assess more vendors, faster and cheaper than
                ever before.
              </p>
              <p className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line26 colorGrey">
                More vendor audits significantly lowers existing vendor risk.
                Swap out insecure vendors for those with better security
                practices..
              </p>
              <MediumRedButton text={"Join Now"} linkTo={"/becomeAMember"} />
            </div>
          </div>
          <div id="howItWorks" className="sections howItWorks">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 colorBlack mobile30MarginB20">
              How it works
            </p>
            <p className="mobileSize18 mobile18Line28 desktopSize20 desktop20Line32 colorGrey mobile18MarginB58">
              Many member companies outsource their vendor due diligence to the
              VSA. We provide a low-cost end-to-end service where 1) your vendor
              completes your selected questionnaire online 2) the results are
              audited by an experienced auditor, and answers are verified by a
              follow up interview if needed 3) You will receive an executive
              summary report for quick decision making, in addition to the
              completed questionnaire.
            </p>
            <div className="stepsWrapper">
              <div className="stepsColumn one">
                <div className="iconDiv">
                  <Image
                    className="stepsIcon"
                    src={require("../../Styles/Images/checkListIcon.png")}
                  />
                </div>
                <div className="stepsText">
                  <p className="mobileSize22 mobile22Line21 desktopSize25 desktop25Line23 colorBlack noMarginB">
                    1. Send your vendor list
                  </p>
                  <p className="mobileSize16 mobile16Line21 desktopSize18 desktop18Line23 colorGrey">
                    We first check if we have recently audited your vendors. If
                    yes, you can immediately access these reports, once the
                    vendor consents to share it.
                  </p>
                </div>
              </div>

              <div className="stepsColumn">
                <div className="iconDiv">
                  <Image
                    className="stepsIcon"
                    src={require("../../Styles/Images/chatIcon.png")}
                  />
                </div>
                <div className="stepsText">
                  <p className="mobileSize22 mobile22Line21 desktopSize25 desktop25Line23 colorBlack noMarginB">
                    2. Contact Vendors
                  </p>
                  <span className="mobileSize16 mobile16Line21 desktopSize18 desktop18Line23 colorGrey">
                    The vendor will be contacted. They will the fill out the VSA
                    questionnaire via our SAAS partner. Once this is completed
                    we will assign an auditor.
                  </span>
                </div>
              </div>

              <div className="stepsColumn">
                <div className="iconDiv">
                  <Image
                    className="stepsIcon"
                    src={require("../../Styles/Images/auditorIcon.png")}
                  />
                </div>
                <div className="stepsText">
                  <p className="mobileSize22 mobile22Line21 desktopSize25 desktop25Line23 colorBlack noMarginB">
                    3. Assign Auditor
                  </p>
                  <span className="mobileSize16 mobile16Line21 desktopSize18 desktop18Line23 colorGrey">
                    When the vendor questions are submitted, an auditor will be
                    assigned. The auditors interviews the vendor to ensure
                    consistency and accuracy of the submitted results.
                  </span>
                </div>
              </div>

              <div className="stepsColumn four">
                <div className="iconDiv">
                  <Image
                    className="stepsIcon"
                    src={require("../../Styles/Images/pieChartIcon.png")}
                  />
                </div>
                <div className="stepsText">
                  <p className="mobileSize22 mobile22Line21 desktopSize25 desktop25Line23 colorBlack noMarginB">
                    4. Generate Report
                  </p>
                  <span className="mobileSize16 mobile16Line21 desktopSize18 desktop18Line23 colorGrey">
                    The report will leverage the scoring process created by the
                    working group. The member will have access to both a summary
                    report and the detailed answers the vendor submitted.
                  </span>
                </div>
              </div>
            </div>{" "}
            {/* steps div */}
          </div>
          <div className="sections">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 colorBlack mobile30MarginB20">
              Why use VSA?
            </p>
            <p className="mobileSize18 mobile18Line28 desktopSize20 desktop20Line30 colorGrey">
              Insecure vendors are the most common cause of data breaches.
            </p>
            <span className="mobileSize16 mobile16Line28 desktop16Line30 colorGrey">
              Regulators require companies to carry out risk-based analysis of
              the security practices of their vendors. The VSA is an industrial
              security standard that can be leveraged to ensure compliance with
              the EU General Data Protection Regulation (GDPR), the California
              Consumer Protection Act (CCPA), and similar regulations. Become a
              member now to get all the benefits.
            </span>
          </div>
          <div id="aboutUs" className="sections aboutUs">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 colorBlack mobile30MarginB20">
              About Us
            </p>
            <p className="mobileSize18 mobile18Line28 desktopSize20 desktop20Line30 colorGrey">
              The Vendor Security Alliance (VSA) is a coalition of companies
              committed to improving Internet security.
            </p>
            <p className="mobileSize16 mobile16Line28 desktop16Line30 colorGrey">
              Every day, industries across the globe depend on each other to
              embrace sound cybersecurity practices: yet in the past companies
              have not had a standardized way to assess the security of their
              peers. The VSA was formed to solve these issues and streamline
              vendor security compliance.
            </p>
            <p className="mobileSize16 mobile16Line28 desktop16Line30 colorGrey">
              In collaboration with the VSA, top security experts and
              experienced compliance officers will release a yearly
              questionnaire to benchmark their risk. Companies can leverage this
              questionnaire to qualify vendors and ensure the appropriate
              controls are in place to improve security for everyone.
            </p>
            <p className="mobileSize16 mobile16Line28 desktop16Line30 colorGrey">
              The VSA is organized as a non-profit organization. Any company
              interested in our mission may apply for membership.
            </p>
            <p className="mobileSize16 mobile16Line28 desktop16Line30 colorGrey">
              The first questionnaire was released on October 1st 2016.
            </p>
            <p className="mobileSize16 mobile16Line28 desktop16Line30 colorGrey">
              The 2019 VSA-Full questionnaire was released on Jan 1st 2019,
              while the 2019 VSA-CORE was released October 24th, 2019. Both are
              available to{" "}
              <a
                href="/downloadQuestionaire"
                target="_self"
                rel="noopener noreferrer"
                className="redLinks"
              >
                download
              </a>
              .
            </p>

            <div className="flexRow justifyCenter">
              <MediumRedButton
                text={"View our Board of Directors"}
                linkTo={"/boardOfDirectors"}
              />
            </div>
          </div>
          <div className="sections">
            <div className="companies">
              <p className="mobileSize25 mobile25Line33 desktopSize30 desktop30Line40 colorBlack mobile25MarginB17 desktop30MarginB23 textCenter">
                Founding Companies
              </p>
              <Carousel
                autoPlay={4000}
                infinite
                slidesPerPage={this.state.slidesPerPage}
                slidesPerScroll={1}
                animationSpeed={2000}
                arrowLeft={
                  <i className="material-icons carouselArrows">
                    keyboard_arrow_left
                  </i>
                }
                arrowRight={
                  <i className="material-icons carouselArrows">
                    keyboard_arrow_right
                  </i>
                }
                addArrowClickHandler
              >
                <Image
                  className="companyLogos carouselImages"
                  src={require("../../Styles/Images/atlassin-logo.png")}
                />
                <Image
                  className="companyLogos carouselImages"
                  src={require("../../Styles/Images/docker-logo.png")}
                />
                <Image
                  className="companyLogos carouselImages"
                  src={require("../../Styles/Images/dropbox-logo.png")}
                />
                <Image
                  className="companyLogos carouselImages"
                  src={require("../../Styles/Images/airbnb-logo.png")}
                />
              </Carousel>
            </div>{" "}
            {/* .companies */}
            <div className="members">
              <p className="mobileSize25 mobile25Line33 desktopSize30 desktop30Line40 colorBlack mobile25MarginB17 desktop30MarginB23 textCenter">
                Some of our Members
              </p>
              <Carousel
                autoPlay={4000}
                infinite
                slidesPerPage={this.state.slidesPerPage}
                slidesPerScroll={1}
                animationSpeed={2000}
                arrowLeft={
                  <i className="material-icons carouselArrows">
                    keyboard_arrow_left
                  </i>
                }
                arrowRight={
                  <i className="material-icons carouselArrows">
                    keyboard_arrow_right
                  </i>
                }
                addArrowClickHandler
              >
                <Image
                  className="memberLogos carouselImages"
                  src={require("../../Styles/Images/coinbase-logo.png")}
                />
                <Image
                  className="memberLogos carouselImages"
                  src={require("../../Styles/Images/replicated-logo.png")}
                />
                <Image
                  className="memberLogos carouselImages"
                  src={require("../../Styles/Images/taskus-logo.png")}
                />
                <Image
                  className="memberLogos carouselImages"
                  src={require("../../Styles/Images/adobe-logo.png")}
                />
              </Carousel>
            </div>{" "}
            {/* .members */}
          </div>
          <div id="contactUs" className="sections last contactUs">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 colorBlack mobile30MarginB20 title">
              Contact Us
            </p>
            <div className="contactInfo">
              <div className="contactDivs general">
                <p className="mobileSize20 mobile20Line26 colorBlack noMarginB">
                  General Enquiries
                </p>
                <a
                  className="mobileSize16 mobile16Line21 redLinks mobile16MarginB20"
                  href="mailto:hello@vendorsecurityalliance.org"
                >
                  hello@vendorsecurityalliance.org
                </a>
              </div>

              <div className="contactDivs press">
                <p className="mobileSize20 mobile20Line26 colorBlack noMarginB">
                  Press Enquiries
                </p>
                <a
                  className="mobileSize16 mobile16Line21 redLinks mobile16MarginB20"
                  href="mailto:press@vendorsecurityalliance.org"
                >
                  press@vendorsecurityalliance.org
                </a>
              </div>

              <div className="contactDivs social">
                <span className="mobileSize20 mobile20Line26 colorBlack">
                  Follow Us
                </span>
                <div className="flexRow">
                  <a
                    href="https://twitter.com/vsa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className="socialIcons twitter"
                      style={{ width: "25px", height: "25px" }}
                      src={require("../../Styles/Images/twitterIcon.png")}
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/VendorSA/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className="socialIcons facebook"
                      style={{
                        width: "25px",
                        height: "25px",
                        margin: "0em 10px"
                      }}
                      src={require("../../Styles/Images/facebookIcon.png")}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/vendor-security-alliance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      className="socialIcons linkedin"
                      style={{ width: "25px", height: "25px" }}
                      src={require("../../Styles/Images/linkedinIcon.png")}
                    />
                  </a>
                </div>
              </div>
            </div>

            {!this.state.submitContactForm ? (
              <Form className="contactForm" onSubmit={this.submitContactForm}>
                <div className="twoColumnRow">
                  <Form.Group
                    controlId="fullName"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Full Name
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="text"
                      placeholder=""
                      onChange={this.getFormInfo}
                      required
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>

                  <Form.Group controlId="email" className="twoColumnRowColumns">
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="email"
                      placeholder=""
                      onChange={this.getFormInfo}
                      required
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>
                </div>

                <Form.Group controlId="subject">
                  <Form.Label className="mobileSize16 mobile16Line21">
                    Subject (optional)
                  </Form.Label>
                  <Form.Control
                    className="inputs"
                    type="text"
                    placeholder=""
                    onChange={this.getFormInfo}
                  />
                  {/* <Form.Text></Form.Text> */}
                </Form.Group>

                <Form.Group controlId="message">
                  <Form.Label className="mobileSize16 mobile16Line21">
                    Your Message (optional)
                  </Form.Label>
                  <Form.Control
                    // className="inputs textArea"
                    as="textarea"
                    rows="12"
                    placeholder=""
                    onChange={this.getFormInfo}
                  />
                  {/* <Form.Text></Form.Text> */}
                </Form.Group>

                <div className="flexRow justifyEnd sendContactBtnDiv">
                  <Button type="submit" className="redButton medium">
                    <span className="mobileSize18 mobile18Line23 weightBold">
                      Send
                    </span>
                  </Button>
                </div>
              </Form>
            ) : (
              <div>
                {" "}
                <p className="mobileSize20 mobile20Line26 colorBlack noMarginB">
                  Thank you for signing up! We will get back to your shortly.
                </p>
              </div>
            )}
          </div>{" "}
          {/* .sections */}
        </div>{" "}
        {/* .mainContent */}
        <Footer />
      </div> // .mainContainer
    );
  }
}
