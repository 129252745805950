import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
  Nav,
} from 'react-bootstrap';


export class DesktopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laoding: false,
    }

    this._isMounted = false;
  }

  render() {
    return (
      <div className='desktopHeader'>
        <div className='flexRow rowBetween dh-inner'>
          <Link to='/'>
            <Image
              className='menuIcon'
              style={{ width: 'auto', height: 'auto' }}
              src={require('../Styles/Images/VSA-logo.png')}
            />
          </Link>

          <Nav className='flexRow alignCenter desktopNav' defaultActiveKey='/' as='ul'>
            <Nav.Item as='li'>
              <Nav.Link className='mobileSize16 mobile16Line21 weightBold colorWhite whiteLinks' href='/downloadQuestionaire' eventKey='link-1'>FREE Questionnaire</Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link className='mobileSize16 mobile16Line21 weightBold colorWhite whiteLinks' href='/becomeAMember' eventKey='link-2'>Become a Member</Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link className='mobileSize16 mobile16Line21 weightBold colorWhite whiteLinks' href='/#howItWorks' eventKey='link-3'>How it Works</Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link className='mobileSize16 mobile16Line21 weightBold colorWhite whiteLinks' href='/#aboutUs' eventKey='link-4'>About Us</Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link className='mobileSize16 mobile16Line21 weightBold colorWhite whiteLinks' href='/#contactUs' eventKey='link-5'>Contact Us</Nav.Link>
            </Nav.Item>
            <Nav.Item as='li'>
              <Nav.Link className='mobileSize16 mobile16Line21 weightBold colorWhite whiteLinks' href='/partners' eventKey='link-6'>Partners</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    )
  }
}