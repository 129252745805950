import React, { Component } from "react";
import { Button } from "react-bootstrap";

export class MediumRedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  clickFunction = () => {
    if (this.props.clickFunction) {
      this.props.clickFunction();
    }
  };

  render() {
    this.text = "";
    this.linkTo = "";

    if (this.props.text) {
      this.text = this.props.text;
    }

    if (this.props.linkTo) {
      this.linkTo = this.props.linkTo;
    }

    return (
      <Button className="redButton medium" onClick={this.clickFunction}>
        <a className="buttonText" href={`${this.linkTo}`}>
          <span className="mobileSize18 weightBold">{this.text}</span>
        </a>
      </Button>
    );
  }
}
