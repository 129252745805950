import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
  Nav,
} from 'react-bootstrap';


export class HamburgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }


  render() {
    return (
      <div className={this.props.menuOpen ? 'hamburgerMenu open' : 'hamburgerMenu closed'}>
        <div className='flexRow alignCenter'>
          <div>
            <Image
              className='closeIcon'
              onClick={this.props.toggleMenu}
              src={require('../Styles/Images/thickGreyCloseIcon.png')}
            />
          </div>

          <div>
            <Link to='/' onClick={this.props.toggleMenu}>
              <Image
                className='logo'
                src={require('../Styles/Images/VSA-logo.png')}
              />
            </Link>
          </div>
        </div>

        <Nav className='hamburgerMenu-list' defaultActiveKey='/' as='ul'>
          <Nav.Item as='li' className='first'>
            <Nav.Link className='links' href='/downloadQuestionaire' onClick={this.props.toggleMenu}>FREE Questionnaire</Nav.Link>
          </Nav.Item>
          <Nav.Item as='li'>
            <Nav.Link className='links' href='/becomeAMember' onClick={this.props.toggleMenu}>Become a Member</Nav.Link>
          </Nav.Item>
          <Nav.Item as='li'>
            <Nav.Link className='links' href='/#howItWorks' onClick={this.props.toggleMenu}>How it Works</Nav.Link>
          </Nav.Item>
          <Nav.Item as='li'>
            <Nav.Link className='links' href='/#aboutUs' onClick={this.props.toggleMenu}>About Us</Nav.Link>
          </Nav.Item>
          <Nav.Item as='li'>
            <Nav.Link className='links' href='/#contactUs' onClick={this.props.toggleMenu}>Contact Us</Nav.Link>
          </Nav.Item>
          <Nav.Item as='li'>
            <Nav.Link className='links' href='/partners'>Partners</Nav.Link>
          </Nav.Item>
        </Nav>
      </ div>
    )
  }
}