const prod = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://yh70gbjdsl.execute-api.us-west-2.amazonaws.com/dev/"
  }
};

const config = prod;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
