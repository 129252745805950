import React, { Component } from 'react';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';


export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktopMode: false,
    }

    this._isMounted = false;
  }

  checkForDesktopMode = () => {
    this.setState({ desktopMode: window.innerWidth >= 1200 });
  }

  componentWillMount() {
    this._isMounted = true;
    this._isMounted && window.addEventListener('resize', this.checkForDesktopMode.bind(this));
    this._isMounted && this.checkForDesktopMode();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.checkForDesktopMode.bind(this))
  }

  render() {
    return (
      <div className='header'>
        {this.state.desktopMode ?
          <DesktopHeader />
          :
          <MobileHeader />
        }
      </div>
    )
  }
}