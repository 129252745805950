import React, { Component } from "react";
import {
  Form,
  Button,
  InputGroup,
  Image,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { DisclaimerModal } from "../../Components/DisclaimerModal";
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/footer";
import { API } from "aws-amplify";

export class DownloadQuestionairePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requested: false,
      showDisclaimer: false,
      chooseQuestionaireError: false,
      getVsaFull: false,
      getVsaCore: false,
      becomeAMember: false,
      fullName: "",
      fullNameError: false,
      workEmail: "",
      workEmailError: false,
      company: "",
      jobTitle: "",
      phoneNumber: "",
      website: ""
    };
  }

  hideDisclaimer = () => {
    if (this.state.showDisclaimer) {
      this.setState({ showDisclaimer: false });
    }
  };

  showDisclaimer = () => {
    if (!this.state.showDisclaimer) {
      this.setState({ showDisclaimer: true });
    }
  };

  checkCheckbox = property => {
    if (this.state[property]) {
      this.setState({
        [property]: false
      });
    } else {
      this.setState({
        [property]: true
      });
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  getQuestionaire = e => {
    e.preventDefault();
    if (this.state.getVsaFull || this.state.getVsaCore) {
      if (this.state.fullName.length >= 4) {
        if (this.state.workEmail) {
          API.post("vsa-frontend", "addquestionnaireuser", {
            body: {
              userName: this.state.fullName,
              userEmail: this.state.workEmail,
              userCompany: this.state.company,
              userJobTitle: this.state.jobTitle,
              userPhone: this.state.phoneNumber,
              userWebsite: this.state.website,
              vsaFullQuestionnaire: this.state.getVsaFull,
              vsaCoreQuestionnaire: this.state.getVsaCore
            }
          })
            .then(response => {
              this.setState({
                requested: true
              });
              API.post("vsa-frontend", "senduseremail", {
                body: {
                  userEmail: this.state.workEmail,
                  vsaFullQuestionnaire: this.state.getVsaFull,
                  vsaCoreQuestionnaire: this.state.getVsaCore
                }
              });
            })
            .catch(error => {
              console.log(error);
            });
          if (this.state.becomeAMember === true) {
            API.post("vsa-frontend", "addmember", {
              body: {
                userName: this.state.fullName,
                userEmail: this.state.workEmail,
                userCompany: this.state.company,
                userJobTitle: this.state.jobTitle,
                userPhone: this.state.phoneNumber,
                userWebsite: this.state.website
              }
            })
              .then(response => {
                this.setState({
                  joinedVsa: true
                });
              })
              .catch(error => {
                console.log(error);
              });
          }
        } else {
          this.setState({
            workEmailError: true
          });
        }
      } else {
        this.setState({
          fullNameError: true
        });
      }
    } else {
      this.setState({
        chooseQuestionaireError: true
      });
    }
  };

  render() {
    return (
      <div className="mainContainer downloadQuestionaire">
        <div className="jumboTron short flexColumn justifyCenter alignCenter">
          <Header />
          <div className="inner">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 weightBold colorWhite textCenter">
              Download the FREE questionnaires
            </p>
          </div>
        </div>
        <div className="mainContent">
          <div className="sections one">
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              The VSA issues two free questionnaires, which will be updated
              annually:
            </p>
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              VSA-Full: This is the classic VSA questionnaire which focuses
              deeply on vendor security. It is used by thousands of companies
              globally.
            </p>
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              VSA-Core: This questionnaire, first available on October 24th,
              2019, comprises the most critical questions on vendor security in
              addition to privacy. The privacy section covers both US Privacy
              (data breach notification requirements plus the new California
              data privacy law (CCPA)), plus EU Privacy (General Data Protection
              Regulation (GDPR)).
            </p>
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              VSA members also gain access to our hosted questionnaire so their
              vendors may complete it easily.
            </p>
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter"></p>
          </div>
          <div className="sections last">
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 colorGrey desktop20MarginB40">
              Complete the form below. We will send you an email to confirm your
              email address and then you will be able to download the
              questionnaire.
            </p>

            {this.state.requested ? (
              <p
                className="mobileSize20 mobile20Line26 weightBold"
                style={{ margin: "3em 0em 6em" }}
              >
                Please check your email to download or view the VSA-Full or
                VSA-Core Questionnaires.
                
                Otherwise download them here: https://vsa-questionnaire-bucket-docs.s3.us-west-2.amazonaws.com/CurrentVSA.zip
              </p>
            ) : (
              <div>
                {this.state.chooseQuestionaireError && (
                  <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 colorRed weightBold">
                    Please choose a questionaire to download
                  </p>
                )}
                <div
                  className="flexRow alignCenter"
                  style={{ marginTop: "0.3em" }}
                >
                  <InputGroup className="forCheckbox">
                    <InputGroup.Checkbox
                      name="getVsaFull"
                      aria-label="checkbox"
                      className={
                        this.state.getVsaFull
                          ? "squareOptions checked"
                          : "squareOptions"
                      }
                      checked={this.state.getVsaFull ? "checked" : ""}
                      readOnly
                      onClick={() => this.checkCheckbox("getVsaFull")}
                    />
                  </InputGroup>

                  <span className="mobileSize16 mobile16Line21">
                    I would like to get the VSA-Full questionnaire
                  </span>

                  <OverlayTrigger
                    placement={"top"}
                    delay={{ show: 500 }}
                    overlay={
                      <Tooltip
                        id={"vsaFullTip"}
                        className="toolTips greyToolTips"
                      >
                        <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 noMarginB textCenter">
                          VSA-FULL: In depth cybersecurity assessment
                        </p>
                      </Tooltip>
                    }
                  >
                    <Image
                      className="questionMark"
                      src={require("../../Styles/Images/questionMark.png")}
                    />
                  </OverlayTrigger>
                </div>

                <div
                  className="flexRow alignCenter"
                  style={{ marginTop: "1.25em" }}
                >
                  <InputGroup className="forCheckbox">
                    <InputGroup.Checkbox
                      name="getVsaCore"
                      aria-label="checkbox"
                      className={
                        this.state.getVsaCore
                          ? "squareOptions checked"
                          : "squareOptions"
                      }
                      checked={this.state.getVsaCore ? "checked" : ""}
                      readOnly
                      onClick={() => this.checkCheckbox("getVsaCore")}
                    />
                  </InputGroup>

                  <span className="mobileSize16 mobile16Line21">
                    I would like the VSA-Core questionnaire
                  </span>

                  <OverlayTrigger
                    placement={"top"}
                    delay={{ show: 500 }}
                    overlay={
                      <Tooltip
                        id={"vsaCoreTip"}
                        className="toolTips greyToolTips"
                      >
                        <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 noMarginB textCenter">
                          VSA-CORE: Essential Cybersecurity & Privacy assessment
                        </p>
                      </Tooltip>
                    }
                  >
                    <Image
                      className="questionMark"
                      src={require("../../Styles/Images/questionMark.png")}
                    />
                  </OverlayTrigger>
                </div>

                <Form
                  className="questionaireForm"
                  onSubmit={this.getQuestionaire}
                >
                  <div className="twoColumnRow">
                    <Form.Group
                      controlId="fullName"
                      className={
                        this.state.fullNameError
                          ? "twoColumnRowColumns formError"
                          : "twoColumnRowColumns"
                      }
                    >
                      <Form.Label className="mobileSize16 mobile16Line21 label">
                        Full Name
                      </Form.Label>
                      <Form.Control
                        className="inputs"
                        type="text"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                      {this.state.fullNameError && (
                        <Form.Text className="mobileSize16 mobile16Line21 colorRed fontItalic">
                          Please enter your full name
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group
                      controlId="workEmail"
                      className={
                        this.state.workEmailError
                          ? "twoColumnRowColumns formError"
                          : "twoColumnRowColumns"
                      }
                    >
                      <Form.Label className="mobileSize16 mobile16Line21 label">
                        Work Email Address
                      </Form.Label>
                      <Form.Control
                        className="inputs"
                        type="email"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                      {this.state.workEmailError && (
                        <Form.Text className="mobileSize16 mobile16Line21 colorRed fontItalic">
                          Please enter your work email address
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group
                      controlId="company"
                      className="twoColumnRowColumns"
                    >
                      <Form.Label className="mobileSize16 mobile16Line21">
                        Company
                      </Form.Label>
                      <Form.Control
                        className="inputs"
                        type="text"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                      {/* <Form.Text></Form.Text> */}
                    </Form.Group>

                    <Form.Group
                      controlId="jobTitle"
                      className="twoColumnRowColumns"
                    >
                      <Form.Label className="mobileSize16 mobile16Line21">
                        Job Title
                      </Form.Label>
                      <Form.Control
                        className="inputs"
                        type="text"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                      {/* <Form.Text></Form.Text> */}
                    </Form.Group>

                    <Form.Group
                      controlId="phoneNumber"
                      className="twoColumnRowColumns"
                    >
                      <Form.Label className="mobileSize16 mobile16Line21">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        className="inputs"
                        type="text"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                      {/* <Form.Text></Form.Text> */}
                    </Form.Group>

                    <Form.Group
                      controlId="website"
                      className="twoColumnRowColumns"
                    >
                      <Form.Label className="mobileSize16 mobile16Line21">
                        Website
                      </Form.Label>
                      <Form.Control
                        className="inputs"
                        type="text"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                      {/* <Form.Text></Form.Text> */}
                    </Form.Group>
                  </div>

                  <div
                    className="flexRow alignCenter"
                    style={{ marginBottom: "1.25em" }}
                  >
                    <InputGroup className="forCheckbox">
                      <InputGroup.Checkbox
                        name="becomeAMember"
                        aria-label="checkbox"
                        className={
                          this.state.becomeAMember
                            ? "squareOptions checked"
                            : "squareOptions"
                        }
                        checked={this.state.becomeAMember ? "checked" : ""}
                        readOnly
                        onClick={() => this.checkCheckbox("becomeAMember")}
                      />
                    </InputGroup>

                    <span className="mobileSize16 mobile16Line21">
                      I want to become a VSA member.{" "}
                      <a
                        href="/becomeAMember"
                        target="_self"
                        rel="noopener noreferrer"
                        className="redLinks"
                      >
                        Why become a member?
                      </a>
                    </span>
                  </div>

                  <p className="desktop16MarginB60">
                    By clicking on the Download Questionnaire button you agree
                    to our{" "}
                    <span className="redLinks" onClick={this.showDisclaimer}>
                      Disclaimer and Limited License
                    </span>
                    .
                  </p>

                  <div className="flexRow justifyCenter downloadBtnDiv">
                    <Button type="submit" className="redButton medium">
                      <span className="mobileSize18 mobile18Line23 weightBold">
                        Download
                      </span>
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>{" "}
          {/* .sections.two */}
        </div>{" "}
        {/* .mainContent */}
        <DisclaimerModal
          hideDisclaimer={this.hideDisclaimer}
          showDisclaimer={this.state.showDisclaimer}
        />
        <Footer />
      </div>
    );
  }
}
