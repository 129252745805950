import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Homepage } from './Homepage/Homepage';
import { DownloadQuestionairePage } from './DownloadQuestionairePage/DownloadQuestionairePage';
import { BecomeAMember } from './BecomeAMember/BecomeAMember';
import { BoardOfDirectors } from './BoardOfDirectors/BoardOfDirectors';
import { Partners } from './Partners/Partners';



export default Router =>
  <Switch>
    <Route path='/' exact component={Homepage} />
    <Route path='/downloadQuestionaire' exact component={DownloadQuestionairePage} />
    <Route path='/becomeAMember' exact component={BecomeAMember} />
    <Route path='/boardOfDirectors' exact component={BoardOfDirectors} />
    <Route path='/partners' exact component={Partners} />
  </Switch>