import React, { Component } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { MediumRedButton } from "../../Components/MediumRedButton";
import { Header } from "../../Components/Header";
import { DisclaimerModal } from "../../Components/DisclaimerModal";
import { Footer } from "../../Components/footer";
import { API } from "aws-amplify";

export class BecomeAMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisclaimer: false,
      joinedVsa: false,
      fullName: "",
      workEmail: "",
      company: "",
      jobTitle: "",
      phoneNumber: "",
      website: ""
    };
  }

  hideDisclaimer = () => {
    if (this.state.showDisclaimer) {
      this.setState({ showDisclaimer: false });
    }
  };

  showDisclaimer = () => {
    if (!this.state.showDisclaimer) {
      this.setState({ showDisclaimer: true });
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  joinVsa = e => {
    e.preventDefault();
    if (this.state.fullName.length >= 4 && this.state.workEmail.length >= 6) {
      API.post("vsa-frontend", "addmember", {
        body: {
          userName: this.state.fullName,
          userEmail: this.state.workEmail,
          userCompany: this.state.company,
          userJobTitle: this.state.jobTitle,
          userPhone: this.state.phoneNumber,
          userWebsite: this.state.website
        }
      })
        .then(response => {
          this.setState({
            joinedVsa: true
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      alert("Please enter your name and email");
    }
    //console.log(this.state);
  };

  render() {
    return (
      <div className="mainContainer becomeAMember">
        <div className="jumboTron short flexColumn justifyCenter alignCenter">
          <Header />
          <div className="inner">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 weightBold colorWhite textCenter">
              Become a VSA member
            </p>
          </div>
        </div>

        <div className="mainContent">
          <div className="sections one">
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              Starting at only $1300 USD per audit we can help you achieve
              better security practices.
            </p>
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              Members of the VSA may leverage our network of third party
              auditors, to carry out risk based assessments of their vendors;
              enabling members to assess more vendors, faster and cheaper than
              ever before.
            </p>
            <div className="desktopShow one">
              <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
                More vendor audits significantly lowers existing vendor risk.
                Swap out insecure vendors for those with better security
                practices.
              </p>
            </div>
          </div>
          <div className="sections last two">
            {this.state.joinedVsa ? (
              <p className="mobileSize20 mobile20Line26 weightBold">
                Thank you for interest in joining the VSA. Please check your
                email for more information regarding your potential membership.
              </p>
            ) : (
              <Form className="memberForm" onSubmit={this.joinVsa}>
                <div className="twoColumnRow">
                  <Form.Group
                    controlId="fullName"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Full Name
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="text"
                      placeholder=""
                      onChange={this.handleChange}
                      required
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>

                  <Form.Group
                    controlId="workEmail"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Work Email Address
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="email"
                      placeholder=""
                      onChange={this.handleChange}
                      required
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>

                  <Form.Group
                    controlId="company"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Company
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="text"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>

                  <Form.Group
                    controlId="jobTitle"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Job Title
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="text"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>

                  <Form.Group
                    controlId="phoneNumber"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="text"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>

                  <Form.Group
                    controlId="website"
                    className="twoColumnRowColumns"
                  >
                    <Form.Label className="mobileSize16 mobile16Line21">
                      Website
                    </Form.Label>
                    <Form.Control
                      className="inputs"
                      type="text"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    {/* <Form.Text></Form.Text> */}
                  </Form.Group>
                </div>

                <div className="desktopShow two">
                  <p className="">
                    By clicking on the Download Questionnaire button you agree
                    to our{" "}
                    <span className="redLinks" onClick={this.showDisclaimer}>
                      Disclaimer and Limited License
                    </span>
                    .
                  </p>
                </div>

                <div className="flexRow justifyCenter becomeAMemberBtnDiv">
                  <Button type="submit" className="redButton medium">
                    <span className="mobileSize18 mobile18Line23 weightBold">
                      Join VSA
                    </span>
                  </Button>
                </div>
              </Form>
            )}
          </div>{" "}
          {/* .sections.two */}
          <div className="sections memberBenefits">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53">
              How VSA Members Benefit
            </p>
            <ul className="ul redList">
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Members get exclusive access to Whistic as a part of VSA
                  membership
                </span>
              </li>
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Members receive notifications via the platform when audit
                  reports are shared
                </span>
              </li>
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Members store shared VSA audit reports within a catalog of all
                  Vendors
                </span>
              </li>
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Members who are already Whistic customers can access VSA audit
                  reports in the same place they manage their entire vendor risk
                  management program
                </span>
              </li>
            </ul>
          </div>
          <div className="sections quote">
            <div className="flexRow">
              <Image
                className="quoteIcons left"
                src={require("../../Styles/Images/leftSideQuote.png")}
              />
              <p className="mobileSize22 mobile22Line35 desktopSize25 desktop25Line40 fontItalic textCenter text">
                The VSA is directly focused on helping companies protect against
                the risks associated with third-party, vendor relationships. We
                needed a partner to help streamline and scale our organization’s
                impact, and we were very impressed with Whistic’s focus on
                vendor security, its commitment to customers, and it’s
                user-interface. We’re happy to partner with the Whistic team and
                provide our members with an intuitive solution for their vendor
                assessment needs.
              </p>
              <Image
                className="quoteIcons right"
                src={require("../../Styles/Images/rightSideQuote.png")}
              />
            </div>
            <p className="mobileSize20 mobile20Line34 colorGrey textRight noMarginB">
              - Dr. Ken Baylor, President, VSA
            </p>
          </div>
          <div className="sections twoColumnRow auditReport">
            <div className="twoColumnRowColumns">
              <p className="mobileSize30 mobile30Line40 mobile30MarginB30 desktopSize40 desktop40Line53 desktop40MarginB50">
                What is included in the VSA Audit Report?
              </p>
              <div className="mobileShow textCenter">
                <Image
                  className="pcImg"
                  style={{ marginBottom: "0.63em" }}
                  src={require("../../Styles/Images/pcImg.png")}
                />
              </div>

              <ul className="ul redList">
                <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                  <span className="colorGrey">
                    Vendor responses to the VSA questionnaire, including
                    comments and documentation
                  </span>
                </li>
                <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                  <span className="colorGrey">
                    Auditor comments, flagged issues and executive summary on
                    the vendor
                  </span>
                </li>
                <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                  <span className="colorGrey">
                    VSA overall score as well as individual question scores set
                    by the auditor
                  </span>
                </li>
              </ul>
            </div>

            <div className="desktopShow twoColumnRowColumns justifyCenter">
              <Image
                className="pcImg"
                // style={{ marginBottom: '0.63em' }}
                src={require("../../Styles/Images/pcImg.png")}
              />
            </div>
          </div>
          <div className="sections last vendorBenefits">
            <p className="mobileSize30 mobile30Line40 mobile30MarginB20 desktopSize40 desktop40Line53 desktop40MarginB50">
              How Vendors Benefit
            </p>
            <ul className="ul redList">
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Vendors respond to the VSA questionnaire in an intuitive,
                  online interface
                </span>
              </li>
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Vendors can add teammates, assign questions, set internal due
                  dates and collaborate using the platform
                </span>
              </li>
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Vendors can use the platform to manage their VSA questionnaire
                  responses over time
                </span>
              </li>
              <li className="mobileSize18 mobile18Line23 desktopSize20 desktop20Line28">
                <span className="colorGrey">
                  Vendors can use the platform to share the completed VSA
                  questionnaire with other companies that want to review their
                  security posture
                </span>
              </li>
            </ul>
            <div className="flexRow justifyCenter">
              <MediumRedButton text={"Learn More"} linkTo={"/"} />
            </div>
          </div>
        </div>

        <DisclaimerModal
          hideDisclaimer={this.hideDisclaimer}
          showDisclaimer={this.state.showDisclaimer}
        />

        <Footer />
      </div>
    );
  }
}
