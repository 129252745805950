import React, { Component } from "react";
import { Header } from "../../Components/Header";
import { BoardOfDirectorsData } from "./BoardOfDirectorsData";
import { DirectorsCard } from "./DirectorsCard";
import { Footer } from "../../Components/footer";

export class BoardOfDirectors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    let bdData = [];
    if (BoardOfDirectorsData) {
      bdData = BoardOfDirectorsData.map((item, index) => {
        return (
          <div className="directorsCardWrappers" key={item.id}>
            <DirectorsCard
              id={item.id}
              image={item.image}
              name={item.name}
              title={item.title}
              linkedinUrl={item.linkedinUrl}
            />
          </div>
        );
      });
    }

    return (
      <div className="mainContainer boardOfDirectors">
        <div className="jumboTron short flexColumn justifyCenter alignCenter">
          <Header />
          <div className="inner">
            <p className="mobileSize30 mobile30Line40 desktopSize40 desktop40Line53 weightBold colorWhite textCenter">
              Board of Directors
            </p>
          </div>
        </div>

        <div className="mainContent">
          <div className="sections last one">
            <p className="mobileSize18 mobile18Line23  desktopSize20 desktop20Line26 textCenter">
              The board is drawn from senior leaders in compliance and security.
            </p>

            <div className="directorsWrapper">
              {bdData.length ? bdData : null}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
