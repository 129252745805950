import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { API } from "aws-amplify";

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      signedUp: false
    };

    this._isMounted = false;
    this.submitContactForm = this.submitContactForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      email: e.target.value
    });
  }

  submitContactForm(e) {
    e.preventDefault();
    API.post("vsa-frontend", "mailchimp", {
      body: {
        userEmail: this.state.email
      }
    })
      .then(response => {
        this.setState({
          email: "",
          signUp: true
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="footer">
        {!this.state.signUp ? (
          <div className="rowOne">
            <p className="mobileSize20 mobile20Line26 colorBlack font20MarginB10">
              Sign up for VSA news
            </p>
            <Form className="form" onSubmit={this.submitContactForm}>
              <Form.Group controlId="Email" className="oneOfTwoColumns">
                <Form.Control
                  className="inputs"
                  type="email"
                  placeholder="Email Address"
                  onChange={this.handleChange}
                  required
                />
                {/* <Form.Text></Form.Text> */}
              </Form.Group>
              <div className="signUpBtnDiv">
                <Button type="submit" className="redButton small">
                  <span className="mobileSize18 weightBold">Sign Up</span>
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div>
            <p
              className="mobileSize20 mobile20Line26 colorBlack font20MarginB10"
              style={{ textAlign: "center" }}
            >
              Thank you for signing up!
            </p>
          </div>
        )}

        <div>
          <p className="mobileSize14 mobile14Line18 colorBlack textCenter noMarginB">
            Copyright 2022 © Vendor Security Alliance |{" "}
            <a
              href="https://www.iubenda.com/privacy-policy/23427419"
              class="iubenda-white no-brand iubenda-embed"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    );
  }
}
